<template>
  <div class="footer">
    <div class="warpper">
      <div class="foot">
        <div class="fc-title">
          <h6>Contact <span style="color: #fff">us</span></h6>
          <p>联系我们</p>
        </div>
        <div class="company">
          <p class="icon01">
            全国销售热线：
			<!-- <b>{{ companyInfo.companyTel }} </b> -->
			     <b>400-133-8979</b>
          </p>
          <p class="icon01">
            售后客服专线：
            <!-- <b>{{ companyInfo.companyTel }} </b> -->
            <b>023-68866321</b>
          </p>
          <!-- <p class="icon01">全国统一服务热线：<b>400-133-8979</b></p> -->
          <p class="icon02">
            电子邮箱：<b>{{ companyInfo.companyEmail }} &nbsp;&nbsp;</b>
          </p>
          <p class="icon03">
            <span>总&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;部：</span>
            <b>{{ companyInfo.companyAddress }} &nbsp;</b>
          </p>
          <!-- <p class="icon03">
            <span>西南集团：</span>
            <b>重庆市荣昌区昌州街道昌龙大道<br>53号（市畜科院5号楼9-10楼） &nbsp;</b>
          </p>
          <p class="icon03">
            <span>西北集团：</span>
            <b>新疆乌鲁木齐市杨子江路198号<br>全季酒店办公区8楼&nbsp;</b>
          </p> -->
        </div>
        <div class="foot-code">
          <!-- 二维码 -->
          <img
            src="@/assets/imgs/ns_erweima.jpg"
            alt=""
            style="width: 100px; height: 100px"
          />
          <img
              src="../../../public/imgs/logo2.jpg"
              alt=""
              style="width: 100px; height: 100px"
          />
<!--          <img-->
<!--              :src="companyInfo.servicePath + companyInfo.companyRqcode"-->
<!--              alt=""-->
<!--              style="width: 100px; height: 100px"-->
<!--          />-->
          <div class="copyright">
            <p>版权所有：{{ companyInfo.companyName }}</p>
            <!-- <p>
              网站建设：<a
                style="color: #d4d4d4"
                @click="() => goXd(companyInfo.companyLink)"
                >{{ companyInfo.companySysSupport }}</a
              >
            </p> -->
            <p>
              备案信息：<a @click="goCheck" style="color: #d4d4d4">{{
                companyInfo.websiteNumber
              }}</a>
            </p>
            <p class="wa" @click="goWa">
              <img src="imgs/wa.png" alt="" />
              <span>渝公网安备50022602000432号</span>
            </p>
          </div>
        </div>
      </div>
      <div class="f-nav">
        <ul>
          <li
            v-for="item in footerMenu"
            :key="item.id"
            @mouseenter="showMenu(item.id)"
            @mouseleave="() => (isShow = '')"
          >
            <a
              class="first"
              @click="() => $router.push({ name: item.url.split('-')[0] })"
              >{{ item.name }}</a
            >
            <!-- v-if="isShow == item.id" -->
            <template>
              <p v-for="el in item.childList" :key="el.id">
                <a class="second" @click="() => goThere(item, el.url)">{{
                  el.name
                }}</a>
              </p>
            </template>
          </li>
        </ul>
        <div class="clears"></div>
      </div>
    </div>
    <div class="backTop">
      <a @click="goTop"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      companyInfo: [],
      footerMenu: [],
      isShow: "",
    };
  },
  methods: {
    getList() {
      this.$post("getcompany").then((res) => {
        this.companyInfo = res;
      });
    },
    getMenu() {
      this.$post(this.$api.MENU.FOOTER_MENU, {
        menuNames: "走进农神,科研创新,集团成员,新闻资讯,技术支撑,友情链接",
      }).then((res) => {
        this.footerMenu = res;
      });
    },
    goTop() {
      let oTop = document.body.scrollTop || document.documentElement.scrollTop;
      let timer = setInterval(() => {
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 50;
          oTop -= 50;
        } else {
          clearInterval(timer);
        }
      }, 10);
    },
    showMenu(id) {
      this.isShow = id;
    },
    goThere(item, url) {
      if (item.name == "友情链接") {
        window.open(url);
      } else {
        this.$router.push({ name: url.split("-")[0] });
      }
    },

    goXd(url) {
      // window.open(url)
    },
    goCheck() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
    goWa() {
      window.open("http://www.beian.gov.cn/portal/index");
    },
  },
  mounted() {
    this.getList();
    this.getMenu();
  },
};
</script>

<style lang="less" scoped>
.warpper {
  width: 1200px;
  margin: 0 auto;
}
.footer {
  padding: 35px 0;
  position: relative;
  background: #333333;
  // height: 469px;
  height: 486px;

  .foot {
    float: left;
    width: 500px;
    font-size: 12px;
    color: #333333;
    line-height: 22px;

    .fc-title {
      text-transform: uppercase;
      font-size: 16px;
      color: #ffffff;
      line-height: 30px;
      margin-bottom: 10px;

      h6 {
        font-size: 30px;
        font-family: Arial;
        color: #ff9c00;
        font-weight: bold;
      }
    }

    .company {
      line-height: 38px;
      font-size: 14px;
      color: #ffffff;

      .icon01 {
        background: url(../../../public/imgs/icon/f01.png) left center no-repeat;
      }

      .icon02 {
        background: url(../../../public/imgs/icon/f03.png) left center no-repeat;
      }

      .icon03 {
        background: url(../../../public/imgs/icon/f02.png) left top 5px
          no-repeat;
        display: flex;
        span {
          flex-shrink: 0;
          flex-grow: 0;
        }
        b {
          width: 220px;
        }
      }

      p {
        padding-left: 35px;
        font-family: Arial;
      }
    }

    .foot-code {
      font-family: Arial;
      color: #999999;
      text-transform: uppercase;
      padding-top: 15px;
      display: flex;
      align-items: center;

      img {
        display: inline-block;
        width: 73px;
        height: 73px;
        margin-right: 5px;
        border: 5px #ffffff solid;
      }

      .copyright {
        display: inline-block;
        color: #d4d4d4;
        margin-left: 10px;
        line-height: 25px;
        margin-top: 30px;
        .wa {
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            border: none;
          }
        }
      }
    }
  }

  .f-nav {
    float: right;
    // padding-top: 60px;

    li {
      float: left;
      list-style: none;
      // margin-left: 35px;
      width: 110px;

      .first {
        display: block;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .second {
        font-size: 12px;
        line-height: 24px;
        color: #d4d4d4;
        &:hover {
          color: #fff;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  .backTop {
    position: absolute;
    right: 10px;
    top: 225px;

    a {
      display: block;
      width: 90px;
      height: 90px;
      background: url(../../../public/imgs/icon/top02.png) left top no-repeat;
      background-size: 100% 100% !important;

      &:hover {
        background: url(../../../public/imgs/icon/top01.png) left top no-repeat;
      }
    }
  }
}
</style>
